import dynamic from 'next/dynamic';
import React, { memo } from 'react';

import ArrowBackIcon from '@/components/shared/element/icons/global/arrow-back';
import ArrowDownwardIcon from '@/components/shared/element/icons/global/arrow-downward';
import ArrowForwardIcon from '@/components/shared/element/icons/global/arrow-forward';
import ArrowUpwardIcon from '@/components/shared/element/icons/global/arrow-upward';
import CalculatorIcon from '@/components/shared/element/icons/global/calculator';
import CancelIcon from '@/components/shared/element/icons/global/cancel';
import CheckCircleIcon from '@/components/shared/element/icons/global/check-circle';
import ChevronLeftIcon from '@/components/shared/element/icons/global/chevron-left';
import ChevronRightIcon from '@/components/shared/element/icons/global/chevron-right';
import CloseIcon from '@/components/shared/element/icons/global/close';
import CompareIcon from '@/components/shared/element/icons/global/compare';
import ExpandLessIcon from '@/components/shared/element/icons/global/expand-less';
import ExpandMoreIcon from '@/components/shared/element/icons/global/expand-more';
import FileDownloadIcon from '@/components/shared/element/icons/global/file-download';
import MapIcon from '@/components/shared/element/icons/global/map';
import OpenInNewIcon from '@/components/shared/element/icons/global/open-in-new';
import PlaceIcon from '@/components/shared/element/icons/global/place';
import PlayRoundIcon from '@/components/shared/element/icons/global/play-round';
import SellYourTruckIcon from '@/components/shared/element/icons/global/sell-your-truck';
import SteeringWheelIcon from '@/components/shared/element/icons/global/steering-wheel';
import TruckIcon from '@/components/shared/element/icons/global/truck';
import { styled } from '@/stitches.config';
import { IIcon } from '@/types/shared/icons';

const globalIcons = {
  'arrow-back': ArrowBackIcon,
  'arrow-downward': ArrowDownwardIcon,
  'arrow-forward': ArrowForwardIcon,
  'arrow-upward': ArrowUpwardIcon,
  calculator: CalculatorIcon,
  cancel: CancelIcon,
  'check-circle': CheckCircleIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  close: CloseIcon,
  compare: CompareIcon,
  'expand-less': ExpandLessIcon,
  'expand-more': ExpandMoreIcon,
  'file-download': FileDownloadIcon,
  map: MapIcon,
  'open-in-new': OpenInNewIcon,
  place: PlaceIcon,
  'play-round': PlayRoundIcon,
  'steering-wheel': SteeringWheelIcon,
  'sell-your-truck': SellYourTruckIcon,
  truck: TruckIcon,
};

const IconStyle = styled('i', {
  position: 'relative',
  fontSize: '0',
  fontStyle: 'normal',
  lineHeight: '0',
  display: 'inline-block',
  verticalAlign: 'middle',
  transitionProperty: 'color, transform, opacity',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  '& svg': {
    width: '100%',
    height: 'auto',
    color: '$current',
    transitionProperty: 'color',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
    '& path, & polygon': {
      fill: 'currentColor',
    },
  },
  variants: {
    size: {
      xs: {
        size: '$size-4',
        minWidth: '$size-4',
      },
      sm: {
        size: '$size-5',
        minWidth: '$size-5',
      },
      base: {
        size: '$size-6',
        minWidth: '$size-6',
      },
      md: {
        size: '$size-7',
        minWidth: '$size-7',
      },
      lg: {
        size: '$size-8',
        minWidth: '$size-8',
      },
      xl: {
        size: '$size-9',
        minWidth: '$size-9',
      },
      '2xl': {
        size: '$size-10',
        minWidth: '$size-10',
      },
      '3xl': {
        size: '$size-12',
        minWidth: '$size-12',
      },
      '4xl': {
        size: '$size-14',
        minWidth: '$size-14',
      },
      '5xl': {
        size: '$size-16',
        minWidth: '$size-16',
      },
    },
    color: {
      'icon-current': {
        color: '$current',
      },
      'icon-red': {
        color: '$primary',
      },
      'icon-blue': {
        color: '$blue',
      },
      'icon-green': {
        color: '$green',
      },
      'icon-green-light': {
        color: '$green-light',
      },
      'icon-bronze': {
        color: '$bronze',
      },
      'icon-silver': {
        color: '$silver',
      },
      'icon-gray': {
        color: '$gray900',
      },
      'icon-gray-light': {
        color: '$gray700',
      },
      'icon-gray-lightest': {
        color: '$gray600',
      },
      'icon-disabled': {
        color: '$gray400',
      },
      'icon-white': {
        color: '$white',
      },
    },
  },
  defaultVariants: {
    size: 'base',
    color: 'icon-gray',
  },
});

interface IconProps extends React.ComponentProps<typeof IconStyle> {
  /**
   * The children of the component.
   *
   * This property is used to pass the SVG icon to be rendered.
   */
  children?: React.ReactNode;
  /**
   * The icon to be displayed
   */
  icon?: IIcon;
}

export const Icon: React.FC<IconProps> = memo(({ icon, children, ...rest }) => {
  const GlobalIconComponent = globalIcons[icon];

  if (GlobalIconComponent) {
    return (
      <IconStyle {...rest}>
        <GlobalIconComponent />
      </IconStyle>
    );
  }

  const SpecificIconComponent = dynamic(
    () => import(`@/components/shared/element/icons/global/${icon}`),
    {
      ssr: false,
    }
  );

  return (
    <IconStyle {...rest}>
      {children ? children : <SpecificIconComponent />}
    </IconStyle>
  );
});

Icon.displayName = 'Icon';
