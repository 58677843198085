import { useEffect, useState } from 'react';

export const isServer = typeof window === 'undefined';

export const isFirefoxBrowser =
  !isServer && navigator.userAgent.toLowerCase().includes('firefox');

export const isIOSDevice =
  !isServer && /iPad|iPhone|iPod/.test(navigator.userAgent);

export function useLineBrowser() {
  const [isLine, setIsLine] = useState(false);

  useEffect(() => {
    setIsLine(navigator.userAgent.includes('Line'));
  }, []);

  return isLine;
}
